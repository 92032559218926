// components/devices/presentation/DeviceControlView.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Chip,
  Alert,
  TextField
} from '@mui/material';
import { 
  Lock,
  Unlock,
  Trash2,
  Wifi,
  WifiOff,
  Edit2
} from 'lucide-react';

const DeviceControlView = ({ 
  device, 
  status = {}, 
  isOnline,
  isLoading,
  error,
  onLock,
  onUnlock,
  onDelete,
  onErrorClear,
  onNameChange
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(device.name);
  const handleSubmit = () => {
    if (editedName.trim() && editedName !== device.name) {
      onNameChange(editedName.trim());
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Escape') {
      setEditedName(device.name);
      setIsEditing(false);
    }
  };

  return (
    <Card sx={{ 
      position: 'relative',
      borderRadius: { xs: 2, sm: 3 },
      boxShadow: { xs: 1, sm: 2 },
      bgcolor: 'background.paper',
      overflow: 'visible'
    }}>
      {/* Online Status Chip */}
      <Box sx={{ 
        position: 'absolute',
        top: 72,
        right: 24,
        zIndex: 1
      }}>
        <Chip
          icon={Boolean(isOnline) ? 
            <Wifi sx={{ width: 16, height: 16 }} /> : 
            <WifiOff sx={{ width: 16, height: 16 }} />
          }
          label={Boolean(isOnline) ? t('online') : t('offline')}
          color={Boolean(isOnline) ? 'success' : 'error'}
          size="small"
          sx={{ boxShadow: 1 }}
        />
      </Box>

      <CardContent sx={{ 
        p: { xs: 2, sm: 3 },
        '&:last-child': { pb: { xs: 2, sm: 3 } }
      }}>
        {/* Header with Name */}
        <Box sx={{ 
          mb: { xs: 3, sm: 3 }
        }}>
          {isEditing ? (
            <TextField
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleSubmit}
              onKeyDown={handleKeyDown}
              autoFocus
              size="small"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: { xs: '1.25rem', sm: '1.25rem' },
                  fontWeight: 600,
                  padding: '4px 8px'
                }
              }}
            />
          ) : (
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover .edit-icon': {
                opacity: 1
              }
            }}>
              <Typography 
                variant="h5" 
                onClick={() => setIsEditing(true)}
                sx={{ 
                  fontWeight: 600,
                  fontSize: { xs: '1.25rem', sm: '1.25rem' },
                  cursor: 'pointer'
                }}
              >
                {device.name}
              </Typography>
              <Edit2
                className="edit-icon"
                size={16}
                onClick={() => setIsEditing(true)}
                sx={{ 
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  color: 'text.secondary',
                  cursor: 'pointer'
                }}
              />
            </Box>
          )}
        </Box>

        {/* Error Alert */}
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: { xs: 2, sm: 3 },
              borderRadius: 1
            }}
            onClose={onErrorClear}
          >
            {error}
          </Alert>
        )}

        {/* Parcel Status */}
        <Box sx={{ 
          mb: { xs: 3, sm: 4 },
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              color: 'text.secondary',
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            {t('parcelStatus')}:
          </Typography>
          <Typography sx={{ 
            fontSize: { xs: '0.875rem', sm: '1rem' },
            fontWeight: 500
          }}>
            {status.isParcelInside ? t('inside') : t('notInside')}
          </Typography>
        </Box>

        {/* Control Buttons */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: { xs: 1.5, sm: 2 },
          mb: { xs: 3, sm: 2 }
        }}>
          <Button
            variant="contained"
            color="error"
            startIcon={<Lock />}
            onClick={onLock}
            disabled={isLoading || status.isLocked || !isOnline}
            sx={{
              height: { xs: '44px', sm: '48px' },
              borderRadius: 2,
              boxShadow: 'none',
              textTransform: 'none',
              '&:hover': { 
                boxShadow: 'none',
                bgcolor: 'error.dark'
              }
            }}
          >
            {t('lock')}
          </Button>

          <Button
            variant="contained"
            color="success"
            startIcon={<Unlock />}
            onClick={onUnlock}
            disabled={isLoading || !status.isLocked || !isOnline}
            sx={{
              height: { xs: '44px', sm: '48px' },
              borderRadius: 2,
              boxShadow: 'none',
              textTransform: 'none',
              bgcolor: '#34A853',
              '&:hover': { 
                boxShadow: 'none',
                bgcolor: '#2E9648'
              }
            }}
          >
            {t('unlock')}
          </Button>
        </Box>

        {/* Delete Button */}
        <Button
          variant="outlined"
          color="error"
          onClick={onDelete}
          disabled={isLoading}
          startIcon={<Trash2 sx={{ 
            width: { xs: 18, sm: 20 },
            height: { xs: 18, sm: 20 }
          }} />}
          sx={{
            width: '100%',
            height: { xs: '44px', sm: '48px' },
            borderRadius: 2,
            borderColor: 'error.light',
            color: 'error.main',
            '&:hover': {
              bgcolor: 'error.50',
              borderColor: 'error.main'
            },
            textTransform: 'none',
            fontSize: { xs: '0.875rem', sm: '0.875rem' }
          }}
        >
          {t('delete')}
        </Button>

      </CardContent>
    </Card>
  );
};

export default DeviceControlView;