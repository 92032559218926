// src/components/devices/presentation/DeviceListView.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeviceControl from '../DeviceControl';
import {
  Container,
  Typography,
  Grid,
  Card,
  Paper,
  LinearProgress,
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from '@mui/material';
import { 
  Package, 
  Search
} from 'lucide-react';

const DeviceListView = ({ 
  devices = [], 
  deviceStatus = {}, 
  deviceConnections = {},
  loading = false,
  error = null,
  dialogOpen = false,
  pairingCode = '',
  pairingLoading = false,
  onErrorClear,
  onDialogOpen,
  onDialogClose,
  onPairingCodeChange,
  onPairing
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Box sx={{ 
        width: '100%', 
        marginTop: { xs: 1, sm: 2 }
      }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ 
      px: { xs: 6, sm: 6, md: 6 },
      py: { xs: 0, sm: 0, md: 0 }
    }}>
      <Box sx={{ 
        mb: { xs: 4, sm: 6 }, 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: { xs: 'stretch', sm: 'flex-start' },
        gap: { xs: 2, sm: 0 }
      }}>
        <Box sx={{ flex: 1 }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 700, 
              mb: 2,
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
            }}
          >
            {t('myDevices')}
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'text.secondary',
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            {t('manageDevices')}
          </Typography>
        </Box>
        
        <Button
          variant="contained"
          startIcon={<Search />}
          onClick={onDialogOpen}
          sx={{ 
            ml: { sm: 3 },
            py: { xs: 1.5, sm: 1 },
            width: { xs: '100%', sm: 'auto' },
            bgcolor: 'primary.main',
            '&:hover': { bgcolor: 'primary.dark' }
          }}
        >
          {t('addDevice')}
        </Button>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 4,
            borderRadius: 2
          }}
          onClose={onErrorClear}
        >
          {error}
        </Alert>
      )}

      {devices.length === 0 ? (
        <Paper sx={{ 
          py: { xs: 6, sm: 8 }, 
          px: { xs: 3, sm: 4 }, 
          textAlign: 'center', 
          borderRadius: 2,
          bgcolor: 'background.paper',
          maxWidth: { sm: '100%' },
          mx: 'auto'
        }}>
          <Box sx={{ 
            width: { xs: 60, sm: 80 }, 
            height: { xs: 60, sm: 80 }, 
            borderRadius: '50%',
            bgcolor: 'grey.100',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mx: 'auto',
            mb: 3
          }}>
            <Package sx={{ 
              width: { xs: 30, sm: 40 }, 
              height: { xs: 30, sm: 40 }, 
              color: 'grey.400' 
            }} />
          </Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('noDevices')}
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'text.secondary',
              maxWidth: '400px',
              mx: 'auto'
            }}
          >
            {t('addDevicePrompt')}
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          {devices.map((device) => (
            <Grid item xs={12} md={6} lg={4} key={device.id}>
              <Card sx={{ 
                position: 'relative',
                height: '100%',
                transition: 'all 0.3s ease',
                borderRadius: 2,
                overflow: 'visible',
                '&:hover': { 
                  transform: { sm: 'translateY(-4px)' },
                  boxShadow: 6 
                }
              }}>
                <DeviceControl 
                  device={device}
                  status={deviceStatus[device.deviceId]}
                  isOnline={deviceConnections[device.deviceId]}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog 
        open={dialogOpen} 
        onClose={onDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            width: { xs: '100%', sm: 'auto' },
            minWidth: { sm: '400px' },
            maxWidth: { xs: 'calc(100% - 32px)', sm: '500px' },
            mx: { xs: 2 },
            borderRadius: { xs: 2, sm: 3 }
          }
        }}
      >
        <DialogTitle sx={{ 
          pt: 3,
          px: { xs: 3, sm: 4 },
          fontSize: { xs: '1.25rem', sm: '1.5rem' }
        }}>
          {t('enterPairingCode')}
        </DialogTitle>
        <DialogContent sx={{ px: { xs: 3, sm: 4 } }}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary', 
              mb: 3,
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            {t('pairingCodeDescription')}
          </Typography>
          <TextField
            autoFocus
            label={t('pairingCode')}
            fullWidth
            value={pairingCode}
            onChange={(e) => onPairingCodeChange(e.target.value.trim())}
            inputProps={{ 
              maxLength: 6,
              pattern: '[0-9]*'
            }}
            error={pairingCode.length > 0 && pairingCode.length !== 6}
            helperText={pairingCode.length > 0 && pairingCode.length !== 6 ? t('errors.invalidPairingCode') : ''}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: { xs: '1rem', sm: '1.125rem' }
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ 
          p: 3, 
          pt: 0,
          gap: 1
        }}>
          <Button 
            onClick={onDialogClose}
            disabled={pairingLoading}
            sx={{ 
              px: { xs: 2, sm: 3 },
              minWidth: { xs: '80px', sm: '100px' }
            }}
          >
            {t('cancel')}
          </Button>
          <Button 
            onClick={onPairing}
            variant="contained"
            disabled={pairingLoading || pairingCode.length !== 6}
            sx={{ 
              px: { xs: 2, sm: 3 },
              minWidth: { xs: '80px', sm: '100px' }
            }}
          >
            {pairingLoading ? <CircularProgress size={24} /> : t('register')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DeviceListView;