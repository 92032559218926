// src/i18n/index.js
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "searchDevice": "Search Device",
      "myDevices": "My Devices",
      "manageDevices": "Manage your smart box",
      "distance": "Distance",
      "parcelStatus": "Parcel Status",
      "inside": "Inside",
      "notInside": "Empty",
      "locked": "Locked",
      "unlocked": "Unlocked",
      "lock": "Lock",
      "unlock": "Unlock",
      "wifiReset": "WiFi Reset",
      "noDevicesFoundScan": "No devices found. Please scan the network.",
      "scanNetwork": "Scan Network",
      "searching": "Searching...",
      "login": "Login",
      "loginDescription": "Safe and easy parcel box service",
      "googleLogin": "Login with Google",
      "logout": "Logout",
      "ipAddress": "IP Address",
      "registeredDate": "Registered Date",
      "deleteDeviceTitle": "Delete Device",
      "deleteDeviceConfirm": "Are you sure you want to delete this device?",
      "searchDeviceDescription": "Search for new smart boxes on your local network",
      "foundDevices": "Found Devices",
      "registeredDevices": "Registered Devices",
      "noDevicesFound": "No Devices Found",
      "noRegisteredDevices": "No Registered Devices",
      "searchingDevices": "Searching for devices...",
      "noDevices": "No Devices",
      "addDevicePrompt": "Add a device to manage your parcels",
      "addDevice": "Add Device",
      "enterPairingCode": "Enter Pairing Code",
      "pairingCode": "Pairing Code",
      "pairingCodeDescription": "Enter the 6-digit pairing code displayed on your device",
      "register": "Register",
      "cancel": "Cancel",
      "deviceOffline": "Device is offline",
      "delete": "Delete Device",
      "welcomeTitle": "Welcome to Smart Box",
      "signInWithGoogle": "Sign in with Google",
      "online": "Online",
      "offline": "Offline",
      "deviceId": "Device ID",
      "deviceDeleted": "Device has been deleted",
      "deviceControl": {
        "locked": "Locked",
        "unlocked": "Unlocked",
        "lockSuccess": "Device locked successfully",
        "unlockSuccess": "Device unlocked successfully",
        "wifiResetConfirm": "WiFi settings will be reset. Device will restart in BLE mode. Continue?"
      },
      "errors": {
        "deviceNotFound": "Device not found",
        "connectionFailed": "Connection failed",
        "lockFailed": "Failed to lock device",
        "unlockFailed": "Failed to unlock device",
        "wifiResetFailed": "Failed to reset WiFi",
        "scanFailed": "Network scan failed",
        "deleteDeviceFailed": "Failed to delete device",
        "loginFailed": "Login failed. Please try again",
        "invalidPairingCode": "Invalid pairing code. Please enter 6 digits",
        "deviceAlreadyRegistered": "This device is already registered",
        "pairingTimeout": "Pairing timeout. Please try again",
        "pairingFailed": "Pairing failed. Please try again",
        "fetchDevices": "Failed to fetch devices",
        "mqttConnectionFailed": "Failed to connect to device",
        "updateNameFailed": "Failed to update device name",
        "emailSendFailed": "Failed to send email"
      },
      "email": {
        "parcelDetected": {
          "subject": "[Smart Box] Parcel Detected - {{deviceName}}",
          "title": "Parcel Detected in Your Smart Box",
          "hello": "Hello,",
          "message": "A parcel has been detected in your smart box {{deviceName}}.",
          "detectedTime": "Detection Time: {{time}}",
          "checkApp": "Please check the app for more details."
        }
      }
    }
  },
  ko: {
    translation: {
      "searchDevice": "디바이스 검색",
      "myDevices": "내 디바이스",
      "manageDevices": "스마트 택배함을 관리하세요",
      "distance": "거리",
      "parcelStatus": "택배 상태",
      "inside": "보관중",
      "notInside": "비어있음",
      "locked": "잠김",
      "unlocked": "열림",
      "lock": "잠금",
      "unlock": "열기",
      "wifiReset": "WiFi 초기화",
      "noDevicesFoundScan": "디바이스를 찾을 수 없습니다. 네트워크를 검색해주세요.",
      "scanNetwork": "네트워크 검색",
      "searching": "검색 중...",
      "login": "로그인",
      "loginDescription": "안전하고 편리한 택배함 서비스",
      "googleLogin": "구글 로그인",
      "logout": "로그아웃",
      "ipAddress": "IP 주소",
      "registeredDate": "등록일",
      "deleteDeviceTitle": "디바이스 삭제",
      "deleteDeviceConfirm": "이 디바이스를 삭제하시겠습니까?",
      "searchDeviceDescription": "로컬 네트워크에서 새로운 택배함을 검색합니다",
      "foundDevices": "발견된 디바이스",
      "registeredDevices": "등록된 디바이스",
      "noDevicesFound": "디바이스를 찾을 수 없습니다",
      "noRegisteredDevices": "등록된 디바이스가 없습니다",
      "searchingDevices": "디바이스 검색 중...",
      "noDevices": "디바이스가 없습니다",
      "addDevicePrompt": "택배함을 추가하여 택배를 관리하세요",
      "addDevice": "디바이스 추가",
      "enterPairingCode": "페어링 코드 입력",
      "pairingCode": "페어링 코드",
      "pairingCodeDescription": "기기에 표시된 6자리 페어링 코드를 입력하세요",
      "register": "등록",
      "cancel": "취소",
      "deviceOffline": "디바이스가 오프라인 상태입니다",
      "delete": "디바이스 삭제",
      "welcomeTitle": "스마트 박스에 오신 것을 환영합니다",
      "signInWithGoogle": "구글로 로그인",
      "online": "온라인",
      "offline": "오프라인",
      "deviceId": "디바이스 ID",
      "deviceDeleted": "디바이스가 삭제되었습니다",
      "deviceControl": {
        "locked": "잠김",
        "unlocked": "열림",
        "lockSuccess": "잠금이 완료되었습니다",
        "unlockSuccess": "잠금이 해제되었습니다",
        "wifiResetConfirm": "WiFi 설정이 초기화됩니다. 기기가 BLE 모드로 재시작됩니다. 계속하시겠습니까?"
      },
      "errors": {
        "deviceNotFound": "디바이스를 찾을 수 없습니다",
        "connectionFailed": "연결에 실패했습니다",
        "lockFailed": "잠금에 실패했습니다",
        "unlockFailed": "잠금 해제에 실패했습니다",
        "wifiResetFailed": "WiFi 초기화에 실패했습니다",
        "scanFailed": "네트워크 검색에 실패했습니다",
        "deleteDeviceFailed": "디바이스 삭제에 실패했습니다",
        "loginFailed": "로그인에 실패했습니다. 다시 시도해주세요",
        "invalidPairingCode": "올바르지 않은 페어링 코드입니다. 6자리 숫자를 입력해주세요",
        "deviceAlreadyRegistered": "이미 등록된 디바이스입니다",
        "pairingTimeout": "페어링 시간이 초과되었습니다. 다시 시도해주세요",
        "pairingFailed": "페어링에 실패했습니다. 다시 시도해주세요",
        "fetchDevices": "디바이스 목록을 불러오는데 실패했습니다",
        "mqttConnectionFailed": "디바이스 연결에 실패했습니다",
        "updateNameFailed": "디바이스 이름 변경에 실패했습니다",
        "emailSendFailed": "이메일 전송 실패"
      },
      "email": {
        "parcelDetected": {
          "subject": "[스마트 박스] 택배가 감지되었습니다 - {{deviceName}}",
          "title": "스마트 박스에 택배가 감지되었습니다",
          "hello": "안녕하세요,",
          "message": "스마트 박스 {{deviceName}}에서 택배가 감지되었습니다.",
          "detectedTime": "감지 시각: {{time}}",
          "checkApp": "앱에서 자세한 내용을 확인해주세요."
        }
      }
    }
  },
  ja: {
    translation: {
      "searchDevice": "デバイス検索",
      "myDevices": "マイデバイス",
      "manageDevices": "スマート宅配ボックスを管理する",
      "distance": "距離",
      "parcelStatus": "荷物状態",
      "inside": "保管中",
      "notInside": "空き",
      "locked": "ロック中",
      "unlocked": "解除中",
      "lock": "ロック",
      "unlock": "解除",
      "wifiReset": "WiFiリセット",
      "noDevicesFoundScan": "デバイスが見つかりません。ネットワークをスキャンしてください。",
      "scanNetwork": "ネットワークスキャン",
      "searching": "検索中...",
      "login": "ログイン",
      "loginDescription": "安全で便利な宅配ボックスサービス",
      "googleLogin": "Googleログイン",
      "logout": "ログアウト",
      "ipAddress": "IPアドレス",
      "registeredDate": "登録日",
      "deleteDeviceTitle": "デバイス削除",
      "deleteDeviceConfirm": "このデバイスを削除してもよろしいですか？",
      "searchDeviceDescription": "ローカルネットワークで新しい宅配ボックスを検索します",
      "foundDevices": "発見されたデバイス",
      "registeredDevices": "登録済みデバイス",
      "noDevicesFound": "デバイスが見つかりません",
      "noRegisteredDevices": "登録済みデバイスがありません",
      "searchingDevices": "デバイスを検索中...",
      "noDevices": "デバイスがありません",
      "addDevicePrompt": "宅配ボックスを追加して荷物を管理する",
      "addDevice": "デバイスを追加",
      "enterPairingCode": "ペアリングコードを入力",
      "pairingCode": "ペアリングコード",
      "pairingCodeDescription": "デバイスに表示される6桁のペアリングコードを入力してください",
      "register": "登録",
      "cancel": "キャンセル",
      "deviceOffline": "デバイスがオフラインです",
      "delete": "デバイスを削除",
      "welcomeTitle": "スマートボックスへようこそ",
      "signInWithGoogle": "Googleでログイン",
      "online": "オンライン",
      "offline": "オフライン",
      "deviceId": "デバイスID",
      "deviceDeleted": "デバイスが削除されました",
      "deviceControl": {
        "locked": "ロック中",
        "unlocked": "解除中",
        "lockSuccess": "ロックが完了しました",
        "unlockSuccess": "ロック解除が完了しました",
        "wifiResetConfirm": "WiFi設定がリセットされます。デバイスはBLEモードで再起動します。続行しますか？"
      },
      "errors": {
        "deviceNotFound": "デバイスが見つかりません",
        "connectionFailed": "接続に失敗しました",
        "lockFailed": "ロックに失敗しました",
        "unlockFailed": "ロック解除に失敗しました",
        "wifiResetFailed": "WiFiリセットに失敗しました",
        "scanFailed": "ネットワークスキャンに失敗しました",
        "deleteDeviceFailed": "デバイスの削除に失敗しました",
        "loginFailed": "ログインに失敗しました。もう一度お試しください",
        "invalidPairingCode": "無効なペアリングコードです。6桁の数字を入力してください",
        "deviceAlreadyRegistered": "このデバイスは既に登録されています",
        "pairingTimeout": "ペアリングがタイムアウトしました。もう一度お試しください",
        "pairingFailed": "ペアリングに失敗しました。もう一度お試しください",
        "fetchDevices": "デバイスリストの取得に失敗しました",
        "mqttConnectionFailed": "デバイスへの接続に失敗しました",
        "updateNameFailed": "デバイス名の更新に失敗しました",
        "emailSendFailed": "メールの送信に失敗しました"
      },
      "email": {
        "parcelDetected": {
          "subject": "[スマートボックス] 荷物が検知されました - {{deviceName}}",
          "title": "スマートボックスで荷物が検知されました",
          "hello": "こんにちは、",
          "message": "スマートボックス {{deviceName}} で荷物が検知されました。",
          "detectedTime": "検知時刻: {{time}}",
          "checkApp": "アプリで詳細をご確認ください。"
        }
      }
    }
  }
};

const getBrowserLanguage = () => {
  const browserlang = navigator.language.toLowerCase().split('-')[0];
  const supportedLanguages = ['ko', 'en', 'ja'];

  return supportedLanguages.includes(browserlang) ? browserlang : 'en';
};

const i18n = i18next.createInstance();

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getBrowserLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;