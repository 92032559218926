// src/components/auth/Login.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert
} from '@mui/material';
import { Package, LogIn } from 'lucide-react';
import LanguageSelector from '../common/LanguageSelector';

const Login = () => {
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const from = location.state?.from?.pathname || '/devices';

  const handleGoogleSignIn = async () => {
    try {
      setError('');
      await signInWithGoogle();
      navigate(from, { replace: true });
    } catch (error) {
      setError(t('errors.loginFailed'));
    }
  };

  return (
<Box
  sx={{
    height: '100vh', // 상단 바를 제외하지 않고 전체 높이 사용
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: '#f5f5f7',
    backgroundImage: 'url("/home-bg.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          maxWidth: '320px', // 폭 축소
          borderRadius: 3,
          p: 4,
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ mb: 4 }}>
          {/* Logo & Title */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between', // 로고와 언어 선택기 간격
            mb: 3
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Package size={28} color="#6366f1" />
              <Typography
                variant="h6"
                sx={{
                  ml: 1.5,
                  fontWeight: 600,
                  color: '#6366f1'
                }}
              >
                Smart Box
              </Typography>
            </Box>
            <LanguageSelector compact />
          </Box>
          
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              textAlign: 'left', // 좌측 정렬
              mb: 1 
            }}
          >
            {t('loginDescription')}
          </Typography>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 3 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleGoogleSignIn}
            startIcon={<LogIn size={18} />}
            sx={{
              py: 1,
              px: 3,
              bgcolor: '#4285f4',
              '&:hover': {
                bgcolor: '#357abd'
              },
              minWidth: '180px', // 버튼 폭 조절
            }}
          >
            {t('signInWithGoogle')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;