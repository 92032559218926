// src/components/layout/Layout.js
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../components/auth/AuthContext';
import Header from './Header';
import { Box } from '@mui/material';

const Layout = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  // 로그인된 상태에서 로그인 페이지 접근 시 디바이스 페이지로 리다이렉트
  if (user && isLoginPage) {
    return <Navigate to="/devices" />;
  }

  return (
    <Box className="min-h-screen bg-gray-50">
      {/* 로그인 페이지가 아닐 때만 Header 표시 */}
      {!isLoginPage && <Header isLoggedIn={!!user} />}
      <Box sx={isLoginPage ? { mt: 0 } : { py: 4 }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;