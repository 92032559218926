// src/components/common/LanguageSelector.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth/AuthContext';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Globe } from 'lucide-react';

const LanguageSelector = ({ compact = false }) => {
  const { i18n } = useTranslation();
  const { user, saveUserLanguage, setCurrentLanguage } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const languages = [
    { code: 'ko', label: '한국어', flag: '🇰🇷' },
    { code: 'en', label: 'English', flag: '🇺🇸' },
    { code: 'ja', label: '日本語', flag: '🇯🇵' }
  ];

  const handleLanguageChange = async (code) => {
    try {
      await i18n.changeLanguage(code);
      if (user && saveUserLanguage) {
        await saveUserLanguage(code);
      } else {
        setCurrentLanguage(code);
      }
      setAnchorEl(null);
    } catch (error) {
      console.error('Language change failed:', error);
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size={compact ? "small" : "medium"}
        sx={{
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          '&:hover': {
            bgcolor: 'rgba(255, 255, 255, 0.9)',
          },
        }}
      >
        <Globe size={compact ? 18 : 24} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            selected={i18n.language === lang.code}
          >
            <ListItemIcon sx={{ fontSize: '1.2rem' }}>
              {lang.flag}
            </ListItemIcon>
            <ListItemText>{lang.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;