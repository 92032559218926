// src/components/auth/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithPopup,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { auth, googleProvider, db } from '../../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import i18next from 'i18next';

const AuthContext = createContext();

const getBrowserLanguage = () => {
  const browserlang = navigator.language.toLowerCase().split('-')[0];
  const supportedLanguages = ['ko', 'en', 'ja'];

  return supportedLanguages.includes(browserlang) ? browserlang : 'en';
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  const saveUserLanguage = async (userId, language) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { language }, { merge: true });
    } catch (error) {
      console.error('Error saving user language:', error);
    }
  };

  const loadUserLanguage = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists() && userDoc.data().language) {
        return userDoc.data().language;
      }
      return null;
    } catch (error) {
      console.error('Error loading user language:', error);
      return null; 
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result;
    } catch (error) {
      console.error('Google sign in error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userLanguage = await loadUserLanguage(currentUser.uid);
        if (userLanguage) {
          i18next.changeLanguage(userLanguage);
        } else if (currentUser) {
          await saveUserLanguage(currentUser.uid, currentLanguage);
        } else {
          i18next.changeLanguage(getBrowserLanguage());
        }
      }
      setUser(currentUser);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentLanguage]);

  const value = {
    user,
    loading,
    signInWithGoogle,
    logout,
    saveUserLanguage: user ? (language) => saveUserLanguage(user.uid, language) : null,
    setCurrentLanguage
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};