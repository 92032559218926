// src/components/devices/DeviceList.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/AuthContext';
import { db } from '../../config/firebase';
import { collection, query, where, onSnapshot, addDoc, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { getFunctions, httpsCallable } from 'firebase/functions';
import mqtt from 'mqtt';
import DeviceListView from './presentation/DeviceListView';

const DeviceList = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [devices, setDevices] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [deviceConnections, setDeviceConnections] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pairingCode, setPairingCode] = useState('');
  const [pairingLoading, setPairingLoading] = useState(false);

  // Firebase devices 불러오기
  const loadDevices = useCallback(async () => {
    if (!user) return;
    
    try {
      const q = query(
        collection(db, 'devices'),
        where('userId', '==', user.uid)
      );
      
      const querySnapshot = await getDocs(q);
      const deviceList = [];
      querySnapshot.forEach((doc) => {
        deviceList.push({ id: doc.id, ...doc.data() });
      });
      
      setDevices(deviceList);
      setLoading(false);
    } catch (error) {
      console.error('Failed to load devices:', error);
      setError(t('errors.loadDevicesFailed'));
      setLoading(false);
    }
  }, [user, t]);

  // 초기 데이터 로드
  useEffect(() => {
    loadDevices();
  }, [loadDevices]);
    
  // Firebase 구독
  useEffect(() => {
    if (!user) return;

    const q = query(
      collection(db, 'devices'),
      where('userId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const deviceList = [];
      snapshot.forEach((doc) => {
        deviceList.push({ id: doc.id, ...doc.data() });
      });
      setDevices(deviceList);
      setLoading(false);
    }, (err) => {
      console.error('Error fetching devices:', err);
      setError(t('errors.fetchDevices'));
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, t]);

    // MQTT 연결 및 구독
    useEffect(() => {
      // 초기에 모든 디바이스를 오프라인으로 설정
      devices.forEach(device => {
        setDeviceConnections(prev => ({
          ...prev,
          [device.deviceId]: false
        }));
      });

      if (devices.length === 0) return;
    
      let mqttClient = null;
      let statusCheckIntervals = {};
      let pingTimeouts = {};
    
      const connectMQTT = () => {
        const options = {
          protocol: 'wss',
          hostname: process.env.REACT_APP_MQTT_HOST,
          port: process.env.REACT_APP_MQTT_PORT,
          username: process.env.REACT_APP_MQTT_USERNAME,
          password: process.env.REACT_APP_MQTT_PASSWORD,
          clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
          protocolVersion: 4,
          clean: true,
          keepalive: 60,
          reconnectPeriod: 5000,
          connectTimeout: 30000,
          rejectUnauthorized: false
        };
    
        mqttClient = mqtt.connect(`wss://${options.hostname}:${options.port}/mqtt`, options);
    
        const checkDeviceStatus = (deviceId) => {
          if (!mqttClient.connected) {
            console.log('MQTT not connected, marking device offline:', deviceId);
            setDeviceConnections(prev => ({
              ...prev,
              [deviceId]: false
            }));
            return;
          }
        
          // 이전 타임아웃이 있다면 제거
          if (pingTimeouts[deviceId]) {
            clearTimeout(pingTimeouts[deviceId]);
          }
        
          console.log('Sending ping to device:', deviceId);
          mqttClient.publish(`smartbox/${deviceId}/ping`, '', { qos: 1 });
        
          // 5초 내에 응답이 없으면 오프라인으로 처리
          pingTimeouts[deviceId] = setTimeout(() => {
            console.log('Ping timeout reached for device:', deviceId);
            setDeviceConnections(prev => ({
              ...prev,
              [deviceId]: false
            }));
          }, 5000);
        };
    
        mqttClient.on('connect', () => {
          console.log('MQTT Connected - Device monitoring');
          // 모든 디바이스를 오프라인으로 초기화
          devices.forEach(device => {
            console.log('Initial offline state for device:', device.deviceId);
            setDeviceConnections(prev => ({
              ...prev,
              [device.deviceId]: false
            }));
          });
        
          devices.forEach(device => {
            const topics = [
              `smartbox/${device.deviceId}/status`,
              `smartbox/${device.deviceId}/ownership/status`
            ];
            topics.forEach(topic => {
              mqttClient.subscribe(topic, { qos: 1 });
            });
        
            // 약간의 딜레이 후 첫 상태 체크 실행
            setTimeout(() => {
              console.log('Initial status check for device:', device.deviceId);
              checkDeviceStatus(device.deviceId);
            }, 1000);
        
            statusCheckIntervals[device.deviceId] = setInterval(() => {
              checkDeviceStatus(device.deviceId);
            }, 60000);
          });
        });
    
        mqttClient.on('message', async (topic, message, packet) => {
          const deviceId = topic.split('/')[1];
        
          if (topic.endsWith('/status') || topic.endsWith('/ownership/status')) {
            try {
              // packet.retain이 true면 retained 메시지
              if (packet.retain) {
                console.log('Ignored retained message for:', deviceId);
                return;
              }
              const status = JSON.parse(message.toString());
              console.log('Received message:', {
                deviceId,
                status,
                topic,
                timestamp: status.timestamp
              });
        
              if (topic.endsWith('/status')) {
                // 이전 상태와 현재 상태 비교
                const prevStatus = deviceStatus[deviceId] || {};
                const newParcelStatus = Boolean(status.isParcelInside);
                const prevParcelStatus = Boolean(prevStatus.isParcelInside);
        
                // 새로운 택배가 감지되었을 때 (isParcelInside가 true이고 reason이 "Parcel detected and locked"일 때)
                if (!prevParcelStatus && newParcelStatus && status.reason === "Parcel detected and locked") {
                  console.log('New parcel detected:', deviceId);
                  const device = devices.find(d => d.deviceId === deviceId);
                  if (device) {
                    const notifyPayload = {
                      owner: user.uid,
                      deviceId: device.deviceId,
                      deviceName: device.name,
                      timestamp: status.timestamp,
                      type: 'email'
                    };
                
                    console.log('Publishing notify message with payload:', notifyPayload);

                    // 기존 상태 메시지에 이메일 발송에 필요한 정보를 포함
                    mqttClient.publish(
                      `smartbox/${deviceId}/notify`, 
                      JSON.stringify(notifyPayload), 
                      { qos: 1, retain: false }, 
                      (err) => {
                        if (err) {
                          console.error('Failed to publish notify message:', err);
                          setError(t('errors.emailSendFailed'));
                        } else {
                          console.log('Notify message published successfully');
                        }
                      }
                    );
                  } else {
                    console.log('Device not found:', deviceId);
                  }
                }
                
                setDeviceStatus(prev => ({
                  ...prev,
                  [deviceId]: status
                }));
              }
        
              // timestamp 비교 로직 제거하고 ping 응답으로만 처리
              if (pingTimeouts[deviceId]) {
                clearTimeout(pingTimeouts[deviceId]);
              }
        
              setDeviceConnections(prev => ({
                ...prev,
                [deviceId]: true
              }));
        
            } catch (error) {
              console.error('Error processing device status:', error);
            }
          }
        });
    
        mqttClient.on('error', (error) => {
          console.error('MQTT Error:', error);
          devices.forEach(device => {
            setDeviceConnections(prev => ({
              ...prev,
              [device.deviceId]: false
            }));
          });
        });
    
        mqttClient.on('offline', () => {
          console.log('MQTT Client went offline');
          devices.forEach(device => {
            setDeviceConnections(prev => ({
              ...prev,
              [device.deviceId]: false
            }));
          });
        });
      };
    
      connectMQTT();
    
      return () => {
        Object.values(statusCheckIntervals).forEach(interval => {
          clearInterval(interval);
        });
        Object.values(pingTimeouts).forEach(timeout => {
          clearTimeout(timeout);
        });
        if (mqttClient) {
          mqttClient.end(true);
        }
      };
    }, [devices]);

  // 페어링 핸들러
  const handlePairing = async () => {
    // 페어링 코드 검증
    if (!pairingCode || pairingCode.length !== 6) {
      setError(t('errors.invalidPairingCode'));
      return;
    }
   
    // 환경 변수 검증 
    if (!process.env.REACT_APP_MQTT_HOST || 
        !process.env.REACT_APP_MQTT_USERNAME || 
        !process.env.REACT_APP_MQTT_PASSWORD) {
      console.error('MQTT configuration missing');
      setError('MQTT configuration missing');
      return;
    }
   
    setPairingLoading(true);
    setError(null);
    let mqttClient;
   
    try {
      console.log('Starting pairing process...');
      const pairingDeviceId = `smartbox_${pairingCode}`; // 임시 페어링용 ID
      const topics = {
        status: `smartbox/${pairingDeviceId}/status`,
        ownershipStatus: `smartbox/${pairingDeviceId}/ownership/status`, 
        ownershipCheck: `smartbox/${pairingDeviceId}/ownership/check`,
        ownershipClaim: `smartbox/${pairingDeviceId}/ownership/claim`
      };
   
      console.log('MQTT Topics:', topics);
   
      const options = {
        protocol: 'wss',
        hostname: process.env.REACT_APP_MQTT_HOST,
        port: process.env.REACT_APP_MQTT_PORT,
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
        clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 0,
        connectTimeout: 60000,
        keepalive: 60,
        rejectUnauthorized: false
      };
   
      console.log('Connecting to MQTT broker with options:', {
        ...options,
        password: '********'
      });
   
      mqttClient = mqtt.connect(`wss://${options.hostname}:${options.port}/mqtt`, options);
   
      await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          console.log('Pairing timeout reached');
          reject(new Error('Pairing timeout'));
        }, 60000);
   
        let receivedInitialStatus = false;
        let deviceRegistered = false;
   
        const statusCheckTimer = setInterval(() => {
          if (!receivedInitialStatus) {
            console.log('Waiting for initial status response...');
            
            mqttClient.publish(topics.ownershipCheck, user.uid, { qos: 1 }, (err) => {
              if (err) console.error('Failed to republish ownership check:', err);
              else console.log('Republished ownership check');
            });
          }
        }, 5000);
   
        mqttClient.on('connect', () => {
          console.log('Successfully connected to MQTT broker');
          
          Object.values(topics).forEach(topic => {
            console.log(`Subscribing to topic: ${topic}`);
            mqttClient.subscribe(topic, { qos: 1 }, (err) => {
              if (err) console.error(`Subscribe error for ${topic}:`, err);
              else console.log(`Successfully subscribed to ${topic}`);
            });
          });
   
          console.log('Publishing initial ownership check');
          mqttClient.publish(topics.ownershipCheck, user.uid, { qos: 1 }, (err) => {
            if (err) console.error('Failed to publish ownership check:', err);
            else console.log('Successfully published ownership check');
          });
        });
   
        mqttClient.on('message', async (topic, message) => {
          const messageStr = message.toString();
          console.log('Received MQTT message:', {
            topic,
            payload: messageStr,
            timestamp: new Date().toISOString()
          });
   
          try {
            if (topic === topics.ownershipStatus) {
              const status = JSON.parse(messageStr);
              console.log('Parsed ownership status:', status);
   
              if (!receivedInitialStatus) {
                receivedInitialStatus = true;
                clearInterval(statusCheckTimer);
                console.log('Received initial status response');
              }
   
              if (!status.owned && !deviceRegistered) {
                deviceRegistered = true;
                console.log('Device is unowned, registering...');
   
                try {
                  // Firebase 등록 시도
                  const deviceData = {
                    userId: user.uid,
                    deviceId: status.deviceId,
                    name: `Smart Box ${new Date().toLocaleDateString()}`,
                    registered: new Date().toISOString()
                  };
   
                  console.log('Attempting to register device with data:', deviceData);
                  await addDoc(collection(db, 'devices'), deviceData);
                  console.log('Device registered in Firebase');
   
                  // 소유권 클레임 메시지 발행
                  const claimTopic = `smartbox/${pairingDeviceId}/ownership/claim`;
                  console.log('Publishing ownership claim to:', claimTopic);
                  mqttClient.publish(claimTopic, user.uid, { qos: 1 }, (err) => {
                    if (err) {
                      console.error('Failed to publish ownership claim:', err);
                      reject(err);
                      return;
                    }
                    console.log('Ownership claim published successfully');
                  });
   
                  try {
                    await loadDevices();
                  } catch (loadError) {
                    console.error('Failed to load devices:', loadError);
                  }
   
                  clearTimeout(timeout);
                  resolve();
                } catch (firebaseError) {
                  console.error('Firebase operation failed:', firebaseError);
                  if (firebaseError.code === 'permission-denied') {
                    reject(new Error('Permission denied. Please check your account permissions.'));
                  } else {
                    reject(firebaseError);
                  }
                }
              } else if (status.owned) {
                console.log('Device is already owned');
                clearTimeout(timeout);
                reject(new Error('Device already registered'));
              }
            }
          } catch (error) {
            console.error('Error processing message:', error);
            reject(error);
          }
        });
   
        mqttClient.on('error', (err) => {
          console.error('MQTT error:', err);
          clearInterval(statusCheckTimer);
          clearTimeout(timeout);
          reject(err);
        });
   
        mqttClient.on('close', () => {
          console.log('MQTT connection closed');
        });
   
      });
   
      setDialogOpen(false);
      setPairingCode('');
   
    } catch (error) {
      console.error('Pairing process error:', error);
      if (error.message === 'Pairing timeout') {
        setError(t('errors.pairingTimeout'));
      } else if (error.message === 'Device already registered') {
        setError(t('errors.deviceAlreadyRegistered'));
      } else {
        setError(t('errors.pairingFailed'));
      }
    } finally {
      if (mqttClient) {
        console.log('Cleaning up MQTT client');
        mqttClient.end();
      }
      setPairingLoading(false);
    }
   };


  return (
    <DeviceListView
      devices={devices}
      deviceStatus={deviceStatus}
      deviceConnections={deviceConnections}
      loading={loading}
      error={error}
      dialogOpen={dialogOpen}
      pairingCode={pairingCode}
      pairingLoading={pairingLoading}
      onErrorClear={() => setError(null)}
      onDialogOpen={() => setDialogOpen(true)}
      onDialogClose={() => {
        setDialogOpen(false);
        setPairingCode('');
        setError(null);
      }}
      onPairingCodeChange={(value) => setPairingCode(value)}
      onPairing={handlePairing}
    />
  );
};

export default DeviceList;