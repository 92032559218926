// Header.js
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/auth/AuthContext';
import LanguageSelector from '../common/LanguageSelector';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  Button,
  Divider,
  Stack
} from '@mui/material';
import { Package, LogOut, Box as BoxIcon } from 'lucide-react';

const Header = ({ isLoggedIn }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  return (
    <AppBar position="static" color="transparent" elevation={1}>
      <Container maxWidth="lg">
        <Toolbar sx={{ padding: '0.5rem 0' }}>
          {/* 로고 수정 */}
          <Link to={isLoggedIn ? "/devices" : "/login"} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Package size={28} color="#6366f1" />
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontWeight: 700,
                  color: '#6366f1',  // 그라디언트 대신 단색 사용
                }}
              >
                Smart Box
              </Typography>
            </Box>
          </Link>

          {/* 로그인 상태일 때만 네비게이션 메뉴 표시 */}
          {isLoggedIn && (
            <Stack direction="row" spacing={1} sx={{ ml: 4 }}>
              <Button
                component={Link}
                to="/devices"
                startIcon={<BoxIcon size={18} />}
                color={isActiveRoute('/devices') ? 'primary' : 'inherit'}
                sx={{
                  bgcolor: isActiveRoute('/devices') ? 'rgba(99, 102, 241, 0.1)' : 'transparent',
                }}
              >
                {t('myDevices')}
              </Button>
            </Stack>
          )}

          {/* 우측 메뉴는 항상 표시 */}
          <Box sx={{ 
            ml: 'auto', 
            display: 'flex', 
            alignItems: 'center',
            gap: 2
          }}>
            {/* 로그인 상태일 때만 이메일 표시 */}
            {isLoggedIn && (
              <>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    display: { xs: 'none', sm: 'block' }
                  }}
                >
                  {user?.email}
                </Typography>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            
            <LanguageSelector />

            {/* 로그인 상태일 때만 로그아웃 버튼 표시 */}
            {isLoggedIn && (
              <Button
                onClick={handleLogout}
                startIcon={<LogOut size={18} />}
                variant="outlined"
                color="error"
                size="small"
              >
                {t('logout')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;