import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import mqtt from 'mqtt';
import DeviceControlView from './presentation/DeviceControlView';

const DeviceControl = ({ device, status = {}, isOnline }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const sendCommand = async (command, successMsg) => {
    setLoading(true);
    setError(null);
    
    try {
      console.log('Connecting to MQTT broker...');
      
      const options = {
        protocol: 'wss',
        hostname: process.env.REACT_APP_MQTT_HOST,
        port: process.env.REACT_APP_MQTT_PORT,
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
        clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
        protocolVersion: 4,
        clean: true,
        keepalive: 60,
        rejectUnauthorized: false
      };

      console.log('MQTT Options:', { ...options, password: '********' });

      const client = mqtt.connect(`wss://${options.hostname}:${options.port}/mqtt`, options);

      await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          console.log('Command timeout reached');
          reject(new Error('Command timeout'));
        }, 5000);
       
        client.on('connect', () => {
          console.log('Connected to MQTT broker');
          
          // 명령 발행
          const commandTopic = `smartbox/${device.deviceId}/command`;
          const message = JSON.stringify({
            action: command,
            timestamp: new Date().toISOString()
          });
          
          console.log('Publishing command:', {
            topic: commandTopic,
            message
          });
       
          client.publish(commandTopic, message, { qos: 1, retain: false }, (err) => {
            clearTimeout(timeout);
            
            if (err) {
              console.error('Publish error:', err);
              reject(err);
              return;
            }
       
            console.log('Command published successfully');
            
            // 상태 요청 발행
            const statusTopic = `smartbox/${device.deviceId}/status/get`;
            client.publish(statusTopic, '', { qos: 1 }, (err) => {
              if (err) {
                console.error('Status request error:', err);
                reject(err);
                return;
              }
              console.log('Status request sent');
              resolve();
            });
          });
        });
       
        client.on('error', (err) => {
          console.error('MQTT error:', err);
          clearTimeout(timeout);
          reject(err);
        });
       
        client.on('message', (topic, message) => {
          console.log('Received MQTT message:', {
            topic,
            message: message.toString()
          });
        });
       });

      setSuccessMessage(successMsg);
      
    } catch (error) {
      console.error(`Command ${command} failed:`, error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleLock = async () => {
    try {
      await sendCommand('lock', t('deviceControl.lockSuccess'));
    } catch (error) {
      setError(t('errors.lockFailed'));
    }
  };

  const handleUnlock = async () => {
    try {
      await sendCommand('unlock', t('deviceControl.unlockSuccess'));
    } catch (error) {
      setError(t('errors.unlockFailed'));
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await sendCommand('factoryReset', '');
      const deviceRef = doc(db, 'devices', device.id);
      await deleteDoc(deviceRef);
      setSuccessMessage(t('deviceDeleted'));
      navigate('/devices');
    } catch (error) {
      console.error('Delete failed:', error);
      setError(t('errors.deleteDeviceFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = async (newName) => {
    setLoading(true);
    try {
      const deviceRef = doc(db, 'devices', device.id);
      await updateDoc(deviceRef, {
        name: newName
      });
    } catch (error) {
      console.error('Failed to update device name:', error);
      setError(t('errors.updateNameFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DeviceControlView
      device={device}
      status={{
        ...status,
        isLocked: Boolean(status.isLocked),
        isParcelInside: Boolean(status.isParcelInside)
      }}
      isOnline={isOnline}
      isLoading={loading}
      error={error}
      successMessage={successMessage}
      onLock={handleLock}
      onUnlock={handleUnlock}
      onDelete={handleDelete}
      onErrorClear={() => setError(null)}
      onSuccessMessageClear={() => setSuccessMessage('')}
      onNameChange={handleNameChange}
    />
  );
};

export default DeviceControl;